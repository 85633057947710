// material-ui
import React, { useContext } from "react";

// project import
import ChatWindow from '../components/chatWindow'
import { AppContext } from '../contexts/AppContext'
import Input from "../components/input";
import { Heading } from "../components/heading"
import ProgressCircle from "../components/progress"

// ==============================|| SAMPLE PAGE ||============================== //

const AskInstalilyPage = () => {
  const { typingIndicator } = useContext(AppContext);
  
  return (
    <div className="chat-box-container">
        <Heading/>
        <div className="window">
            <ChatWindow />
        </div>

        {/* <div className="custom-query-area" >
            {typingIndicator && <ProgressCircle/>}
            {!typingIndicator && <Input/>}  
        </div> */}
    </div>
  );
};

export default AskInstalilyPage;
