import React, { useContext } from "react";
import "../styles/askInstalily.css";
import { AppContext } from "../contexts/AppContext";

const ProgressCircle = () => {

    const { stopGenerating } = useContext(AppContext);
      
    return (
        <div className="progress-input-area">
            <div className="progress-circle-container" onClick={stopGenerating}>
                <div className="progress-circle"></div>
                <div className="stop-square"></div> 
            </div>
        </div>
    );
};
  
export default ProgressCircle;