import React, { useEffect, useRef, useContext } from "react";
import "../styles/askInstalily.css";
import { marked } from "marked";
import { AppContext } from "../contexts/AppContext";

function ChatWindow() {
    const { messages, registerResetRoleHeadings } = useContext(AppContext);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        registerResetRoleHeadings(() => {
            renderedRoles.current = {};
        });
    }, [registerResetRoleHeadings]);

    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const colors = [
        "#b7d8a9", "#FFD6A5", "#A0C4FF","#fff59e","#e1bfff","#ffabb5","#abeeff"
    ];

    const roleColorMap = {};

    const getColorForRole = (role) => {
        if (!roleColorMap[role]) {
            const availableColors = colors.filter(color => !Object.values(roleColorMap).includes(color));
            roleColorMap[role] = availableColors[0] || '#FFFFFF';
        }
        return roleColorMap[role];
    };

    let renderedRoles = {};

    return (
        <div className="messages-container">
            {messages.map((message, index) => {
                const showRoleHeading = !renderedRoles[message.role];
                if (showRoleHeading) renderedRoles[message.role] = true;

                return (
                    <div key={index}>
                        {message.content && (
                            <>
                                {showRoleHeading && (
                                    <div className="role-heading" style={{color: getColorForRole(message.role)}}>{message.role}</div>
                                )}
                                <div className="message assistant-message" style={{backgroundColor: getColorForRole(message.role)}}>
                                    <div dangerouslySetInnerHTML={{ __html: marked(message.content).replace(/<p>|<\/p>/g, "") }}></div>
                                </div>
                            </>
                        )}
                    </div>
                );
            })}
            <div ref={messagesEndRef} />
        </div>
    );
}

export default ChatWindow;

