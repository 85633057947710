import React, { useContext } from "react";

import { AppContext } from "../contexts/AppContext";
import "../styles/askInstalily.css";
import IconButton from '@mui/material/IconButton';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import poweredby from "../media/powered.svg";
import RestartAltIcon from '@mui/icons-material/RestartAlt';

export function Heading() {
    const { handleClearState } = useContext(AppContext);

    return (
        <>
        <div className="top-container">
            <div className="powered-by">
                <img src={poweredby} alt="Powered by Instalily"></img>
            </div>
            <IconButton onClick={handleClearState} aria-label="delete" style={{ marginRight: '30px' }}>
                <RestartAltIcon />
            </IconButton>
        </div>
        </>
  );
}
