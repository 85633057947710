import React, { createContext, useState, useEffect } from "react";
import useLocalStorage from '../hooks/useLocalStorage';
import socketIOClient from "socket.io-client";
//import axios from 'axios';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {

    const ENDPOINT = "http://127.0.0.1:5001";

    // http://127.0.0.1:5001/webhook_intermediate_message
    // http://127.0.0.1:5001/webhook_output_message
    
    const [ghostMessage, setGhostMessage] = useState(false);
    const [messages, setMessages] = useLocalStorage("messages",[]);
    const [typingIndicator, setTypingIndicator] = useState(false);

    const stopGenerating = () => {
        setTypingIndicator(false);
    };

    const [resetRoleHeadings, setResetRoleHeadings] = useState(() => () => {});


    function handleClearState() {
        setMessages([])
        resetRoleHeadings();
    };

    const registerResetRoleHeadings = (resetFunction) => {
        setResetRoleHeadings(() => resetFunction);
    };

    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);

        socket.on("new_message", data => {
            if (data.clear === 'true') {
                setMessages([])
                resetRoleHeadings();
            } 
            setMessages(prevMessages => {
                if (prevMessages.some(msg => msg.id === data.id)) {
                    return prevMessages; 
                } else {
                    return [...prevMessages, {
                        id: data.id,
                        role: data.agent_name,
                        content: JSON.stringify(data.message).replace(/^"|"$/g, ''),
                    }];
                }
            });
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    return (
        <AppContext.Provider
        value={{messages, setMessages, typingIndicator, stopGenerating, ghostMessage, handleClearState, registerResetRoleHeadings }}>
        {children}
        </AppContext.Provider>
    );
};
