import { AppProvider } from './contexts/AppContext'
import AskInstalilyPage from './pages/askInstalily'
import "./styles/askInstalily.css";

function App() {
  return (
    <>
    <AppProvider>
      <AskInstalilyPage/>
    </AppProvider>
    </>
  );
}

export default App;
